import React from "react"
import SEO from '../components/SEO'
import * as styles from './legal.module.less'

const Privacy = () => (
  <>
    <SEO title="隐私政策" />

    <main className={styles.container}>
      <h1>RAPNETBUY（宝猫科技）隐私政策</h1>
      <section>
        <p>
          RAPNETBUY（宝猫科技）网站www.rapnetbuy.com（以下称“本平台”）非常注重保护用户（包括但不限于通过有线或移动设备访问本平台的个人及企业）的个人信息及隐私。鉴于您在使用本平台的服务时，本平台可能会收集和使用您的相关信息，为向您阐明用户信息收集、使用、共享、管理及保护的规则，特制定本《RAPNETBUY（宝猫科技）隐私政策》（以下或称“本政策”）。并特别提醒您：
        </p>
        <p>
          <strong>本政策不适用于其他第三方向您提供的服务。例如RAPNETBUY（宝猫科技）上入驻的卖家依托本平台向您提供服务时，您向卖家提供的个人信息不适用本政策。</strong>
        </p>
        <p>
          <strong>本政策与用户所使用的本平台服务信息相关，在使用RAPNETBUY（宝猫科技）各项产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用RAPNETBUY（宝猫科技）各项产品或服务，即表示您已充分理解并同意本政策。</strong>
        </p>
        <p>
          如对本政策或相关事宜有任何问题，请通过客服热线：13266166161 与本平台联系。
        </p>

        <section>
          <h3>一、用户信息的收集及使用</h3>
          <p>本平台提供服务时，可能会收集、储存和使用下列与用户有关的信息。如果用户不提供相关信息，可能无法注册成为本平台的会员或无法享受本平台提供的某些服务，或者无法达到相关服务拟达到的效果。</p>
          <h3>1、用户提供的信息</h3>
          <p>您使用邮箱成为RAPNETBUY（宝猫科技）会员时，您需要提供邮箱地址，并设置会员密码。如果您提供以下额外资料补全企业认证信息，将可使用本平台提供的浏览、搜索、在线交易服务以及更个性化的产品或服务：企业名称、企业营业执照、营业执照注册号、企业地址、固定电话、身份证号码、法人身份证照片、企业银行账户信息等。如果您不提供这些信息，将会影响到您使用高级搜索、在线交易等服务。</p>
          <h3>2、平台获取的用户信息</h3>
          <p>为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账号异常状态，本平台会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
日志信息：当您使用本平台的产品或服务时，本平台会自动收集您对本平台服务的详细使用情况，作为有关网络日志保存。如您的搜索信息、IP地址、访问日期和时间及您访问的网页记录等。</p>

          <p>交易信息：为展示您账户的订单信息，例如货物信息、款项信息、时间信息，本平台会收集您在使用本平台服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。</p>

          <p>本网站将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>


          <h3>二、用户信息的共享、转让和公开披露</h3>
          <p>除以下情况外，本平台不会将您的个人信息提供给RAPNETBUY（宝猫科技）以外的任何个人、公司或组织共享、转让给和公开披露：</p>
          <p>（1）依据本政策或事先已获得您明确的同意或授权；</p>
          <p>（2）根据适用的法律、法规、法律程序的要求、行政或司法的强制性要求所必须时；</p>
          <p>（3）在法律、法规允许的范围内，为维护RAPNETBUY（宝猫科技）及关联方或合作伙伴、您或其他RAPNETBUY（宝猫科技）用户或社会公共利益、财产或安全免遭损害时；</p>
          <p>（4）据与您签署的相关协议或其他法律文件的约定。</p>
          <p>您理解并同意，根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人/企业信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>


          <h3>三、用户个人信息的保护</h3>
          <p>1、本平台已采取符合法律规定、合理可行的安全防护措施和制度保护您个人信息的安全，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在某些服务中，本平台将利用加密技术来保护用户提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。用户需要了解，用户接入本平台的服务所用的系统和通讯网络，有可能因本平台可控范围外的因素而出现问题。</p>
          <p>2、本网站会采取合理可行的措施以避免收集无关的个人信息。本网站只会在达成本政策所述目的所需期限内保留您的个人信息，除非需要延长保留期或应法律法规的允许或要求。</p>
          <p>3、在使用RAPNETBUY（宝猫科技）服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披 露自己的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络RAPNETBUY（宝猫科技），以便本平台根据您的申请采取相应措施。</p>
          <p>4、在不幸发生个人信息安全事件后，本平台将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、本平台已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况本平台将以信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，本平台会采取合理、有效的方式发布公告。同时，本平台还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>


          <h3>四、用户个人信息的保存、访问和管理</h3>
          <h3>1、用户个人信息的保存</h3>
          <p>您的个人信息将全部被存储于中华人民共和国境内，但以下情形除外：</p>
          <p>（1）法律法规另有明确规定；</p>
          <p>（2）获得您的明确授权；</p>
          <p>（3）您通过互联网进行跨境交易等个人主动行为。</p>
          <p>针对以上情形，本平台会依据本政策及相关法律法规对您的个人信息提供保护。</p>
          <h3>2、用户个人信息的访问</h3>
          <p>您有权通过以下方式访问您的个人信息，但法律法规另有规定，或本政策另有约定除外。</p>
          <p>账户信息：若您希望访问或编辑您的账户中的个人基本资料等信息，您可登录账号通过“用户中心”执行此类操作。</p>
          <p>订单信息：您可登录账号通过“交易中心”访问或查看您的订单记录、交易记录等。</p>
          <p>若您无法通过上述路径访问该等信息，您可通过RAPNETBUY（宝猫科技）客服与本平台联系。</p>
          <h3>3、用户个人信息的更正、补充</h3>
          <p>当您发现您的个人信息有误或需要补充时，您可通过RAPNETBUY（宝猫科技）客服与本平台联系，提出更正或补充申请。</p>
          <h3>五、Cookie 及类似技术的使用</h3>
          <p>当用户访问设有 Cookies 装置的本平台时，本平台服务器会自动发送 Cookies 至用户浏览器中，同时储存进用户的电脑硬盘内，此 Cookies 便负责记录日后用户访问本平台时的种种操作、浏览消费习惯、信用记录等。运用 Cookies 技术，RAPNETBUY（宝猫科技）能够为您提供更加周到的个性化服务。RAPNETBUY（宝猫科技）将运用 Cookies 技术向用户提供其感兴趣的信息资料或为其储存密码。</p>
          <h3>六、未成年人的特别注意事项</h3>
          <p>
            <strong>如果您未满 18 周岁，您无权使用本平台产品或服务，因此，请您不要在本平台从事交易行为。</strong>
          </p>
          <h3>七、隐私政策更新</h3>
          <p>您理解并同意，本平台有权根据业务发展的需要单方修订本政策，并以修订后的版本完全替代修订前的版本。请您及时关注和了解本政策的修订情况，若您不同意修订后版本，您应立即停止使用RAPNETBUY（宝猫科技）服务，否则即视同您同意并完全接受修订后的版本。</p>

          <h3>八、联系方式</h3>
          <p>
            您可以通过以下方式与本平台联系，本平台将在15天内回复您的请求：
          </p>
          <p>如对本政策或相关事宜有任何问题，请通过客服热线：13266166161 与本平台联系。</p>
          <p>如果您对本平台的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，<strong>您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案</strong> 。</p>

          <p style={{ textAlign: 'right' }}>发布日期：2021年04月26日</p>
        </section>
      </section>
    </main>
  </>
)

export default Privacy
